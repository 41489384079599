<template>
  <div class="app-container">
    <login/>
    <download-app/>
    <router-view/>
  </div>
</template>
<script>
// import {storageAvailable} from "@/libraries/Storage";
import Login from "./views/Login/Login.vue";
import DownloadApp from "./components/downloadApp/DownloadApp.vue"
// import "@/assets/styles/__fonts.sass"

export default {
  data(){
    return {}
  },
  components:{
    Login,
    DownloadApp
  },
  beforeCreate() {
    // if (!storageAvailable('localStorage')){
    //   alert("localstorage is not available")
    // }else{
    //   alert("localstorage is available")
    // }
    // if (!storageAvailable('sessionStorage')){
    //   alert("sessionStorage is not available")
    // }else{
    //   alert("sessionStorage is available")
    // }
  }
}
</script>
<style lang="sass">
.app-container
  overflow: hidden
</style>
